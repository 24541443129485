import React from "react"
import AboutUs from "../components/aboutPage/aboutUs"
import OurTeam from "../components/aboutPage/ourTeam"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import CookieBar from "../components/cookieBar"
import ServiceMenu from "../components/services/servicesMenuHeader"

export default () =>(
    <div id="about">
        <SEO title={'ICON Worldwide | About Us - Software Development Company'} 
        description="We don’t follow agency rules – we make them! Discover our software development company and close-knit team which is keen into AI, Blockchain and IoT projects."
        canonical={'https://icon-worldwide.com/about'}/>
        <div className="about-us-sec">
            <CookieBar/>
            <Header bgcolor="#292929"/>
            <div id="mobile-menu" ><ServiceMenu bgcolor="#292929"/></div>
            <AboutUs/>
        </div>
        <OurTeam/>
        <Footer noScrollbar="true"/>
    </div>
)
