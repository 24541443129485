import React from "react"
import arrow from "../../../assets/images/aboutPage/arrowDown-white.png"

export default () => 
<div className="about-section">
    <div className="about-herospace">            
        <h1>About Us<span></span></h1>
    </div>
    <div className="about-inner">
        <div className="left-section">
            <div className="section-inner">
                <p>Yes, we’re like other agencies.  We have strategists, project managers, designers and developers.  
                    And like other agencies, we work hard for our clients, we have great parties and super cute dogs running around the office.
                     Unlike other agencies, we are Design & Technology under one ICON global brand, independently owned and operated for over
                     12 years. And, unlike other agencies, many of our clients have stayed with us since the beginning. </p>
                <p>We have continuously evolved, and always work to stay in front of the latest design and technology trends from around the world. 
                    Our international presence gives us a unique world view. We do not work in country silos, but rather as a global team on global projects.
                    For us, it’s personal.  We know our clients have a lot at stake and we over-deliver to make every program a success. There are 
                    no walls, you know every person on your team and they know your business your brand and every line of your code.</p>
                <p>Our leaders have been in the game for 20 plus years learning from and working with the best in the business. This experience powers every project.
                     Our award-winning design team helps big and emerging brands stand apart in the market combining super intuitive user-interfaces to hand drawn 
                     illustrations and 3-D animations. Our tech team can build literally... anything. We are constantly investing in technology for the future.
                    We have built one of the strongest technology teams in the agency world, building everything from enterprise-level .com experiences to creative AI, Blockchain and IoT projects.</p>
                <p>We have the fullest stacks in the business! We don’t follow agency rules – we make them!</p>
            </div>
        </div>
        <div className="right-section">
            <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <div className="section-inner">
                <div className="stats">
                    <h2>5</h2>
                    <h3>Offices</h3>
                    <p>Internationally Active</p>
                </div>
                <div className="stats">
                    <h2>6</h2>
                    <h3>Awards</h3>
                    <p>Received</p>
                </div>
                <div className="stats">
                    <h2>12</h2>
                    <h3>Years Old</h3>
                    <p>Independently Owned</p>
                </div>
                <div className="stats">
                    <h2>33</h2>
                    <h3>Employees</h3>
                    <p>Our Mission is Your Success</p>
                </div>
                <div className="stats">
                    <h2>500+</h2>
                    <h3>Apps</h3>
                    <p>Deployed</p>
                </div>
                <div className="stats">
                    <h2>2000+</h2>
                    <h3>Digital Projects</h3>
                    <p>Launched</p>
                </div>
            </div>
        </div>
        <div className="badges">    
        <a  target="_blank" href="https://www.goodfirms.co/company/icon-worldwide"><img src="https://goodfirms.s3.amazonaws.com/badges/blue-button/top-website-development-companies.svg" alt="Swiss software development company, ICON Worldwide, GoodFirms Badge" title=" ICON Worldwide Swiss software development company, GoodFirms Badge"/></a>
        <a  href="https://www.sortlist.ch/agency/icon-worldwide-ag"><img src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-blue-light-xl.svg" alt="Swiss software development company, ICON Worldwide, flag" title=" ICON Worldwide Swiss software development company, flag"/></a>
        </div>
    </div>
</div>