import React from "react"

import arrow from "../../../assets/images/servicesPages/arrowDown.png"

import jon from "../../../assets/images/aboutPage/team_images/jon_2.png"
import lori from "../../../assets/images/aboutPage/team_images/lori.png"
import richard from "../../../assets/images/aboutPage/team_images/Bostock.png"
import momo from "../../../assets/images/aboutPage/team_images/Monira.png"

import zita from "../../../assets/images/aboutPage/team_images/zita.png"
import lazar from "../../../assets/images/aboutPage/team_images/lazar.png"
import ilko from "../../../assets/images/aboutPage/team_images/ilko.png"
import yovana from "../../../assets/images/aboutPage/team_images/yovana.png"

import peter from "../../../assets/images/aboutPage/team_images/peter.png"
import toli from "../../../assets/images/aboutPage/team_images/toli_2.png"
import atanas from "../../../assets/images/aboutPage/team_images/atanas.png"
import kamen from "../../../assets/images/aboutPage/team_images/kamen.png"

import dimitar from "../../../assets/images/aboutPage/team_images/dimitar.png"
import anjelika from "../../../assets/images/aboutPage/team_images/anjelika.png"
import sabri from "../../../assets/images/aboutPage/team_images/sabri_2.png"
import eray from "../../../assets/images/aboutPage/team_images/eray_2.png"

import kris from "../../../assets/images/aboutPage/team_images/kristian.png"
import martin from "../../../assets/images/aboutPage/team_images/Martin.png"
import valya from "../../../assets/images/aboutPage/team_images/valya.png"


export default () =>(
    <div className="about-inner">

        <h2>Our Team<span></span></h2>

        <div className="team-inner">

            <div className="person">
                <img className="person-img" src={jon} alt="Swiss software development company, ICON Worldwide team, Jon" title="ICON Worldwide Swiss software development company, team, Jon"/>
                <div className="person-info">
                    <h3>Jon</h3>
                    <p>Managing Director</p>
                </div>
                <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow" className="arrow"/>
            </div>

            <div className="person">
                <img className="person-img" src={lori} alt="Swiss software development company, ICON Worldwide team, Lori" title="ICON Worldwide Swiss software development company, team, Lori"/>
                <div className="person-info">
                    <h3>Lori</h3>
                    <p>Creative Director</p>
                </div>
            </div>


            <div className="person">
                <img className="person-img" src={richard} alt="Swiss software development company, ICON Worldwide team, Richard" title="ICON Worldwide Swiss software development company, team, Richard"/>
                <div className="person-info">
                    <h3>Richard</h3>
                    <p>Sr. Project Manager</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={momo} alt="Swiss software development company, ICON Worldwide team, Momo" title="ICON Worldwide Swiss software development company, team, Momo"/>
                <div className="person-info">
                    <h3>Monira</h3>
                    <p>Designer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={zita} alt="Swiss software development company, ICON Worldwide team, Zita" title="ICON Worldwide Swiss software development company, team, Zita"/>
                <div className="person-info">
                    <h3>Zita</h3>
                    <p>Project Manager</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={lazar} alt="Swiss software development company, ICON Worldwide team, Lazar" title="ICON Worldwide Swiss software development company, team, Lazar"/>
                <div className="person-info">
                    <h3>Lazar</h3>
                    <p>Project Manager</p>
                </div>
                <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow" className="arrow"/>
            </div>

            <div className="person">
                <img className="person-img" src={ilko} alt="Swiss software development company, ICON Worldwide team, Ilko" title="ICON Worldwide Swiss software development company, team, Ilko"/>
                <div className="person-info">
                    <h3>Ilko</h3>
                    <p>Jr. Project Manager</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={yovana} alt="Swiss software development company, ICON Worldwide team, Yovana" title="ICON Worldwide Swiss software development company, team, Yovana"/>
                <div className="person-info">
                    <h3>Yovana</h3>
                    <p>Q/A Engineer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={peter} alt="Swiss software development company, ICON Worldwide team, Peter" title="ICON Worldwide Swiss software development company, team, Peter"/>
                <div className="person-info">
                    <h3>Peter</h3>
                    <p>Lead Developer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={toli} alt="Swiss software development company, ICON Worldwide team, Toli" title="ICON Worldwide Swiss software development company, team, Toli"/>
                <div className="person-info">
                    <h3>Toli</h3>
                    <p>Lead Developer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={atanas} alt="Swiss software development company, ICON Worldwide team, Atanas" title="ICON Worldwide Swiss software development company, team, Atanas"/>
                <div className="person-info">
                    <h3>Atanas</h3>
                    <p>Sr. Developer</p>
                </div>
                <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow" className="arrow"/>
            </div>

            <div className="person">
                <img className="person-img" src={kamen} alt="Swiss software development company, ICON Worldwide team, Kamen" title="ICON Worldwide Swiss software development company, team, Kamen"/>
                <div className="person-info">
                    <h3>Kamen</h3>
                    <p>Sr. Developer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={dimitar} alt="Swiss software development company, ICON Worldwide team, Dimitar" title="ICON Worldwide Swiss software development company, team, Dimitar"/>
                <div className="person-info">
                    <h3>Dimitar</h3>
                    <p>Sr. Developer</p>
                </div>
                <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow" className="arrow"/>
            </div>

            <div className="person">
                <img className="person-img" src={anjelika} alt="Swiss software development company, ICON Worldwide team, Anjelika" title="ICON Worldwide Swiss software development company, team, Anjelika"/>
                <div className="person-info">
                    <h3>Anjelika</h3>
                    <p>Developer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={sabri} alt="Swiss software development company, ICON Worldwide team, Sabri" title="ICON Worldwide Swiss software development company, team, Sabri"/>
                <div className="person-info">
                    <h3>Sabri</h3>
                    <p>Developer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={eray} alt="Swiss software development company, ICON Worldwide team, Eray" title="ICON Worldwide Swiss software development company, team, Eray"/>
                <div className="person-info">
                    <h3>Eray</h3>
                    <p>Developer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={kris} alt="Swiss software development company, ICON Worldwide team, Kristian" title="ICON Worldwide Swiss software development company, team, Kristian"/>
                <div className="person-info">
                    <h3>Kristian</h3>
                    <p>Jr. Developer</p>
                </div>
            </div>

            <div className="person">
                <img className="person-img" src={martin} alt="Swiss software development company, ICON Worldwide team, Martin" title="ICON Worldwide Swiss software development company, team, Martin"/>
                <div className="person-info">
                    <h3>Martin</h3>
                    <p>Jr. Developer</p>
                </div>
                <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow" className="arrow"/>
            </div>

            <div className="person">
                <img className="person-img" src={valya} alt="Swiss software development company, ICON Worldwide team, Valya" title="ICON Worldwide Swiss software development company, team, Valya"/>
                <div className="person-info">
                    <h3>Valya</h3>
                    <p>SEO, SEM, Social Media</p>
                </div>
            </div>

        </div>
    </div>
)
